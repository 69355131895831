const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://taxijas.dvgeo.app' : 'http://192.168.100.106:3034',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://taxijas.dvgeo.app' : 'http://192.168.100.106:3034',
    api: 'api/',
    apiSocket: 'taxijas::1.2',
    nameDir: 'taxijas',
    package: 'app.dvgeo.taxijas.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyBYAyjFCkW5sIv6Zc8vD4gD3pp86XS1Mos',
    appName: 'Taxi Jas',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#FF7601',
    colorDark: '#d25e01',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.taxijas.passenger',
    playStoreDriverId: 'app.dvgeo.taxijas.driver',
    appStorePassengerId: '',
    appStoreDriverId: '',
    email: "corgogato16jasgp@gmail.com",
};
export default config;
